import React from 'react';
import MediaQuery from 'react-responsive';
import './style.scss';

 import videoBg from '../../assets_global/video/27club-bg.mp4';
// import videoBgMobile from '../../assets_global/video/27club-bg-mobile.mp4';
// import videoPoster from '../../assets_global/images/video-bg-poster.jpg';
// import videoPosterMobile from '../../assets_global/images/video-bg-poster-mobile.jpg';


//import videoBg from '../../assets_global/video/bg-animated-event.mp4';
import videoBgMobile from '../../assets_global/video/bg-animated-event-mobile.mp4';
import videoPoster from '../../assets_global/images/video-bg-event-poster.jpg';
import videoPosterMobile from '../../assets_global/images/video-bg-event-poster-mobile.jpg';

class VideoBg extends React.Component {

  render() {
    return (
      <>

        <div className="video-bg">

          <MediaQuery minWidth={851}>
          <video className="top-video" playsInline loop autoPlay muted poster={videoPoster}>
            <source src={videoBg} type="video/mp4" />
          </video>
          </MediaQuery>

          <MediaQuery maxWidth={850}>
          <video className="top-video" playsInline loop autoPlay muted poster={videoPosterMobile}>
            <source src={videoBgMobile} type="video/mp4" />
          </video>
          </MediaQuery>
        </div>

        {/* <div className="video-overlay"></div> */}
       
        <div className="callout-inner">
  
        </div>
      </>
    );
  }
}

export default VideoBg;