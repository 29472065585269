import React from "react";
import './style.scss';

class LostTapesLiveButton extends React.Component {
  render() {
    return (
      <svg version="1.1" className="lost-tapes-live-button" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px"
        viewBox="0 0 302 55.7">
        <defs>

        </defs>
        <g>
          <g>
            <path class="st0" d="M38.7,36.8v2.4H24V17.5h2.7v19.3H38.7z" />
            <path class="st0" d="M41.8,28.1c0-3.6,1.8-6.2,4.7-7.6c-1.1,2-1.5,4.6-1.5,7.6c0,3.1,0.6,5.9,1.8,7.9
			C43.7,34.7,41.8,32.1,41.8,28.1z M52.4,17.1c2.6,0,4.5,1.3,5.6,3.4c-1.5-0.7-3.5-1.1-5.6-1.1c-2.2,0-4.2,0.4-5.9,1.1
			C47.7,18.4,49.6,17.1,52.4,17.1z M46.8,36.1c1.6,0.7,3.5,1.1,5.6,1.1c2.2,0,4.1-0.3,5.6-1.1c-1.1,2.1-3,3.4-5.6,3.4
			C49.9,39.5,48,38.2,46.8,36.1z M59.6,28.1c0-3-0.5-5.7-1.6-7.7c3,1.3,4.7,3.9,4.7,7.7c0,3.9-1.7,6.6-4.7,7.9
			C59.1,34.1,59.6,31.3,59.6,28.1z"/>
            <path class="st0" d="M74.6,39.7c-4.1,0-7.8-2.5-7.8-7.7h2.9c0,3.6,2.4,5.2,5.1,5.2c2.4,0,4.3-1.3,4.3-3.6c0-2.9-3.2-3.4-5.2-4.2
			c-3.1-1.1-6.3-2.3-6.3-6.5c0-4.2,3.2-6.1,6.7-6.1c3.7,0,6.9,2,7.1,6.3h-2.7c-0.1-2.7-2.1-3.8-4.3-3.8c-2.1,0-4.1,1.1-4.1,3.6
			c0,2.4,1.8,3.3,4.5,4.2c3,1,7.1,1.8,7.1,6.3C81.8,37.6,78.3,39.7,74.6,39.7z"/>
            <path class="st0" d="M84,17.5h4.9c1.7,0,2.8,0.7,2.9,2.2H84V17.5z M91.8,19.6h2.6v19.5h-2.6V19.6z M97.4,17.5h4.9v2.2h-7.8
			C94.6,18.1,95.7,17.5,97.4,17.5z"/>
            <path class="st0" d="M111.3,17.5h4.9c1.7,0,2.8,0.7,2.9,2.2h-7.8V17.5z M119.1,19.6h2.6v19.5h-2.6V19.6z M124.6,17.5h4.9v2.2h-7.8
			C121.8,18.1,122.9,17.5,124.6,17.5z"/>
            <path class="st0" d="M143.8,39.2l-1.8-5.9h-8l-1.8,5.9h-2.7l7-21.7h3.3l7,21.7H143.8z M134.7,30.9h6.5L138,20.4L134.7,30.9z" />
            <path class="st0" d="M150.3,17.5h7.7c2.6,0,4.2,1,5.1,2.5c-0.9-0.2-1.9-0.3-2.7-0.3H153v9.2h7.4c0.9,0,1.9-0.1,2.9-0.3
			c-0.8,1.7-2.4,2.7-5.1,2.7H153v8h-2.7V17.5z M164.1,24.2c0-1.6-0.3-3.1-0.9-4.3c1.9,0.5,3.5,1.6,3.5,4.3c0,2.6-1.6,3.8-3.4,4.3
			C163.8,27.4,164.1,25.9,164.1,24.2z"/>
            <path class="st0" d="M169.8,23.6v-6.2h14.1v2.2h-11.5v7.1C170.4,26.6,169.8,25.6,169.8,23.6z M169.8,32.3c0-1.7,0.7-2.9,2.6-3.2
			v7.8h11.8v2.2h-14.4V32.3z M172.4,26.8c0.3,0,0.6,0,1,0h10V29h-10c-0.3,0-0.7,0-1,0.1V26.8z"/>
            <path class="st0" d="M195.8,39.7c-4.1,0-7.8-2.5-7.8-7.7h2.9c0,3.6,2.4,5.2,5.1,5.2c2.4,0,4.3-1.3,4.3-3.6c0-2.9-3.2-3.4-5.2-4.2
			c-3.1-1.1-6.3-2.3-6.3-6.5c0-4.2,3.2-6.1,6.7-6.1c3.7,0,6.9,2,7.1,6.3h-2.7c-0.1-2.7-2.1-3.8-4.3-3.8c-2.1,0-4.1,1.1-4.1,3.6
			c0,2.4,1.8,3.3,4.5,4.2c3,1,7.1,1.8,7.1,6.3C203,37.6,199.6,39.7,195.8,39.7z"/>
            <path class="st0" d="M215.5,17.5h2.6V37h12v2.2h-14.6V17.5z" />
            <path class="st0" d="M233.7,17.5h2.6v21.7h-2.6V17.5z" />
            <path class="st0" d="M254.3,17.5h2.8l-7.7,21.7h-2.2l-7.7-21.7h2.9l5.9,18L254.3,17.5z" />
            <path class="st0" d="M260.5,23.6v-6.2h14.1v2.2h-11.5v7.1C261.2,26.6,260.5,25.6,260.5,23.6z M260.5,32.3c0-1.7,0.7-2.9,2.6-3.2
			v7.8h11.8v2.2h-14.4V32.3z M263.1,26.8c0.3,0,0.6,0,1,0h10V29h-10c-0.3,0-0.7,0-1,0.1V26.8z"/>
          </g>
        </g>
        <g>
          <path class="st1" d="M287.4,1H14.8C7.2,1,1,7.2,1,14.8v26.1c0,7.6,6.2,13.8,13.8,13.8h272.4c7.6,0,13.8-6.2,13.8-13.8V14.5" />
          <g>
            <polyline class="st1" points="291.3,1 301,1 301,10.7 		" />
            <line class="st1" x1="301" y1="1" x2="289.3" y2="12.7" />
          </g>
        </g>
      </svg>

    );
  }
}

export default LostTapesLiveButton;