import React from "react";
import './style.scss';

class YoutubeButton extends React.Component {
  render() {
    return (
      <svg version="1.1" className="youtube-button" x="0px" y="0px"
	 viewBox="0 0 203.7 55.7">

        <g>
          <g>
            <path className="st0" d="M37.1,39.2v-8.8l-7.9-13.7h3.3l6.2,11.3l6.2-11.3h3l-7.9,13.7v8.8H37.1z"/>
            <path className="st0" d="M49,27.7c0-3.8,1.9-6.4,4.9-7.9c-1.1,2.1-1.6,4.8-1.6,7.9c0,3.2,0.6,6.1,1.9,8.2C50.9,34.6,49,31.8,49,27.7z
              M60,16.2c2.7,0,4.6,1.4,5.9,3.5c-1.6-0.7-3.6-1.1-5.9-1.1c-2.3,0-4.4,0.4-6.1,1.2C55.1,17.6,57.1,16.2,60,16.2z M54.1,36
              c1.6,0.7,3.6,1.1,5.9,1.1c2.3,0,4.2-0.4,5.9-1.1c-1.2,2.2-3.1,3.6-5.9,3.6C57.4,39.5,55.4,38.2,54.1,36z M67.5,27.7
              c0-3.1-0.5-5.9-1.6-7.9c3.1,1.4,4.9,4.1,4.9,7.9c0,4.1-1.8,6.9-4.9,8.2C67,33.9,67.5,31,67.5,27.7z"/>
            <path className="st0" d="M91.6,31.2c0,2.8-0.8,4.9-2.4,6.3c-1.6,1.5-3.7,2.2-6.3,2.2c-2.6,0-4.7-0.7-6.4-2.2c-1.6-1.5-2.4-3.6-2.4-6.3
              V16.6h2.8V31c0,2.1,0.6,3.6,1.7,4.6c1.1,1.1,2.6,1.6,4.3,1.6c1.8,0,3.2-0.5,4.3-1.4c1.1-0.9,1.7-2.4,1.7-4.4V16.6h2.7V31.2z"/>
            <path className="st0" d="M105.3,19.1v20.1h-2.8V19.1h-7.7v-2.5H113v2.5H105.3z"/>
            <path className="st0" d="M116,31.1V16.6h2.8v18c0,0.5,0.1,1,0.2,1.4C117,35.1,116,33.4,116,31.1z M119,36c1.4,0.7,3.3,1,5.6,1
              c2.3,0,4.2-0.4,5.6-1c-0.6,2-2.6,3.5-5.6,3.5C121.6,39.5,119.6,38,119,36z M130.4,34.6v-18h2.8v14.5c0,2.3-1,3.9-3.1,4.9
              C130.4,35.6,130.4,35.1,130.4,34.6z"/>
            <path className="st0" d="M138.4,16.6h9.5c2.4,0,3.7,0.9,4.1,2.4c-0.6-0.2-1.2-0.2-1.8-0.2h-9v7.7h9.7c0.5,0,0.9-0.1,1.3-0.2v2.6
              c-0.4-0.1-0.8-0.2-1.3-0.2h-9.7V37h9.6c0.4,0,0.8,0,1.2-0.1c-0.5,1.4-1.5,2.3-3.8,2.3h-9.8V16.6z M152.2,35.1v-6.1
              c1.8,0.4,3.1,1.8,3.1,3.9c0,2.2-1.3,3.7-3.3,4.1C152.1,36.3,152.2,35.7,152.2,35.1z M152.2,20.5c0-0.5-0.1-1-0.2-1.4
              c1.8,0.5,3.1,1.6,3.1,3.7c0,1.8-1.1,3.2-2.9,3.6V20.5z"/>
            <path className="st0" d="M159,16.6h14.5v2.5h-11.7v7.4h11.2V29h-11.2v7.7h12.3v2.5H159V16.6z"/>
          </g>
        </g>
        
        <g>
          <path className="st1" d="M189.2,1H14.8C7.2,1,1,7.2,1,14.8v26.1c0,7.6,6.2,13.8,13.8,13.8h174.1c7.6,0,13.8-6.2,13.8-13.8V14.5"/>
          <g>
            <polyline className="st1" points="193,1 202.7,1 202.7,10.7 		"/>
            <line className="st1" x1="202.7" y1="1" x2="191" y2="12.7"/>
          </g>
        </g>
      </svg>

    );
  }
}

export default YoutubeButton;
