import React from "react";
import './style.scss';

class LearnMoreButton extends React.Component {
  render() {
    return (
      <svg version="1.1" className="learnmore-button" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px"
	 viewBox="0 0 235 55.7" >
        <g>
          <g>
            <g>
              <path className="st0" d="M20.2,39.2V16.6h2.8v20h11.4v2.5H20.2z"/>
              <path className="st0" d="M37.8,23v-6.4h14.6v2.3H40.5v7.4C38.5,26.1,37.8,25.1,37.8,23z M37.8,32c0-1.7,0.7-3,2.7-3.3v8.1h12.3v2.3
                h-15V32z M40.5,26.3c0.3,0,0.6,0,1,0h10.3v2.3H41.5c-0.4,0-0.7,0-1,0.1V26.3z"/>
              <path className="st0" d="M60.6,33.1l-2.4,6.1h-2.9l9-22.5h2.8l9.3,22.5h-3.1l-2.4-6.1H60.6z M65.6,19.9l-4.1,10.7h8.4L65.6,19.9z"/>
              <path className="st0" d="M79.8,16.6h9.4c2.3,0,3.6,0.9,4.1,2.4c-0.8-0.2-1.6-0.3-2.6-0.3h-8.1v8.5h9.5c0.5,0,1-0.1,1.4-0.2v2.6
                c-0.4-0.1-0.9-0.2-1.4-0.2h-9.5v9.6h-2.8V16.6z M93.6,21c0-0.7-0.1-1.4-0.2-1.9c2,0.5,3.2,1.9,3.2,4.1c0,2.1-1.2,3.5-3,4V21z
                M93.6,29.7c1.8,0.4,2.9,1.7,2.9,4.1v5.4h-2.9V29.7z"/>
              <path className="st0" d="M114.9,39.2l-11.6-18.3v18.3h-2.7V16.6h3.3l11.2,17.8V16.6h2.7v22.5H114.9z"/>
              <path className="st0" d="M148.4,39.2V20.8l-7.1,18.4h-2.1l-7.1-18.4v18.4h-2.6V16.6h4l6.8,17.9l6.9-17.9h3.9v22.5H148.4z"/>
              <path className="st0" d="M154.8,27.7c0-3.8,1.9-6.4,4.9-7.9c-1.1,2.1-1.6,4.8-1.6,7.9c0,3.2,0.6,6.1,1.9,8.2
                C156.7,34.6,154.8,31.8,154.8,27.7z M165.8,16.2c2.7,0,4.6,1.4,5.9,3.5c-1.6-0.7-3.6-1.1-5.9-1.1c-2.3,0-4.4,0.4-6.1,1.2
                C160.9,17.6,162.9,16.2,165.8,16.2z M159.9,36c1.6,0.7,3.6,1.1,5.9,1.1c2.3,0,4.2-0.4,5.9-1.1c-1.2,2.2-3.1,3.6-5.9,3.6
                C163.2,39.5,161.2,38.2,159.9,36z M173.3,27.7c0-3.1-0.5-5.9-1.6-7.9c3.1,1.4,4.9,4.1,4.9,7.9c0,4.1-1.8,6.9-4.9,8.2
                C172.7,33.9,173.3,31,173.3,27.7z"/>
              <path className="st0" d="M187.9,29.6h-5v9.6h-2.7V16.6h8.4c2.2,0,4,0.6,5.2,1.8c1.2,1.2,1.9,2.6,1.9,4.3c0,1.4-0.4,2.7-1.2,3.9
                c-0.8,1.2-2,2-3.5,2.4l6.6,10.1h-3.2L187.9,29.6z M188.4,27.1c1.4,0,2.6-0.4,3.4-1.3c0.8-0.8,1.2-1.8,1.2-3c0-1.1-0.4-2-1.1-2.7
                c-0.8-0.8-1.8-1.1-3.2-1.1h-5.8v8.1H188.4z"/>
              <path className="st0" d="M200.4,16.6h14.5v2.5h-11.7v7.4h11.2V29h-11.2v7.7h12.3v2.5h-15.1V16.6z"/>
            </g>
          </g>
          <g>
            <path className="st1" d="M220.4,1H14.8C7.2,1,1,7.2,1,14.8v26.1c0,7.6,6.2,13.8,13.8,13.8h205.4c7.6,0,13.8-6.2,13.8-13.8V14.5"/>
            <g>
              <polyline className="st1" points="224.3,1 234,1 234,10.7 			"/>
              <line className="st1" x1="234" y1="1" x2="222.3" y2="12.7"/>
            </g>
          </g>
        </g>
      </svg>

    );
  }
}

export default LearnMoreButton;