import React from "react";
import Layout from "../../Layout/Layout";
import ReactGA from "react-ga";
import { Fade } from "react-awesome-reveal";
import Collapsible from "react-collapsible";
//import ScrollableAnchor from 'react-scrollable-anchor';
import Scrollchor from "react-scrollchor";
//import { configureAnchors } from 'react-scrollable-anchor';
//import MediaQuery from 'react-responsive';

//import NavBar from '../../components/NavBar/NavBar';
import NavBarNoSticky from "../../components/NavBarNoSticky/NavBarNoSticky";

import VideoBg from "../../components/VideoBg/VideoBg";
import VideoEventBg from "../../components/VideoEventBg/VideoEventBg";
//import SpotifyEmbed from '../../components/SpotifyEmbed/SpotifyEmbed';

import MediaPlayer from "../../components/MediaPlayer/MediaPlayer";
import bounceArrow from "../../assets_global/images/arrow-bounce.png";
import lemmonLogo from "../../assets_global/images/lemmon-logo.png";
import SpotifyButton from "../../components/svgButtons/SpotifyButton/SpotifyButton";
import YoutubeButton from "../../components/svgButtons/YoutubeButton/YoutubeButton";
import OverTheBridgeButton from "../../components/svgButtons/OverTheBridgeButton/OverTheBridgeButton";
import LearnMoreButton from "../../components/svgButtons/LearnMoreButton/LearnMoreButton";
import LemmonentButton from "../../components/svgButtons/LemmonentButton/LemmonentButton";
import LostTapesLiveButton from "../../components/svgButtons/LostTapesLiveButton/LostTapesLiveButton";

import "./style.scss";

class Home extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
    };

    this.gaClick = (Category, Event, Label) => (event) => {
      ReactGA.event({
        category: Category,
        action: Event,
        label: Label,
      });
    };

    // convoluted way to add google tracking to media player
    if ("mediaSession" in navigator) {
      var currentSong;
      setInterval(function () {
        const allAudio = Array.from(document.querySelectorAll("audio"));
        //const isPlaying = [...allAudio].some(item => !item.paused);

        var i;
        for (i = 0; i < allAudio.length; i++) {
          if (allAudio[i].currentTime < 5 && !allAudio[i].paused) {
            currentSong = navigator.mediaSession.metadata.title;
            console.log(currentSong);
            ReactGA.event({
              category: "Player",
              action: "Click",
              label: currentSong,
            });
          }
        }

        // if(isPlaying) {
        //   console.log(isPlaying);
        //   currentSong = navigator.mediaSession.metadata.title;
        //   console.log('something is playing...' + currentSong);
        // } else {
        //   console.log('paused or skipped');

        // }
      }, 5000);
    }
  }

  componentDidMount() {
    // ReactGA.initialize('UA-100773913-15',{
    //   debug: true,
    // });
    // ReactGA.pageview(window.location.pathname + window.location.search);

    ReactGA.initialize("UA-100773913-15", {
      debug: true,
    });
    ReactGA.pageview(window.location.pathname + window.location.search);
  }

  render() {
    return (
      <>
        <Layout pageCurrent="home">
          <header id="top">
            <NavBarNoSticky />

            <section className="blockwrap callout">
              {/* <NavBar /> */}
              {/* L|o|st |t| a |p| e s o|f| |t|h |e| 2|7| cl|ub| */}

              <Fade triggerOnce delay={1000}>
                <div className="title-wrapper">
                  <h1>
                    <span className="mobile-break">
                      Lo<span className="messinasans">st</span>{" "}
                      <span className="title-fix-spacing">T</span>
                      <span className="messinasans">a</span>pe
                      <span className="messinasans">s</span>
                    </span>
                    <br className="mobile-break" />{" "}
                    <span className="mobile-break">
                      <span className="messinasans">O</span>f T
                      <span className="messinasans">h</span>e{" "}
                      <span className="messinasans">2</span>7{" "}
                      <span className="messinasans">Cl</span>ub{" "}
                    </span>
                  </h1>
                  <p>
                    Using AI to create the album lost to music’s mental
                    health&nbsp;crisis.
                  </p>
                </div>
              </Fade>
              <VideoBg />
              <div className="bounce-arrow">
                <Scrollchor
                  animate={{ offset: 0, duration: 200 }}
                  to="#the-album"
                >
                  <img
                    className="bounce-arrow"
                    alt="arrow bounce"
                    src={bounceArrow}
                  />
                </Scrollchor>
              </div>
            </section>
          </header>

          <section className="blockwrap playlist" id="the-album">
            <div className="playlist-spotify">
              {/* <Fade triggerOnce fraction="0.5" direction="right" delay={500}> */}
              {/* <SpotifyEmbed /> */}
              <MediaPlayer />
              <p>Also available on:</p>
              <div className="button-wrapper">
                <a
                  onClick={this.gaClick("Link", "Click", "Spotify")}
                  href="https://open.spotify.com/artist/0bbvGPgU1zw9zpJB4GEAhI?si=8ZHgPBNYQiWQLKyM17zgmA"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <SpotifyButton />
                </a>{" "}
                <a
                  onClick={this.gaClick("Link", "Click", "Youtube")}
                  href="https://music.youtube.com/channel/UCY9V---i40sDhehiNNJVQBQ"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <YoutubeButton />
                </a>
              </div>

              {/* </Fade> */}
            </div>

            <div className="playlist-copy">
              <div className="playlist-copy-wrapper">
                {/* <Fade triggerOnce fraction="0.5" direction="left" delay={100}> */}
                <p>
                  As long as there’s been popular music, musicians and crews
                  have struggled with mental health at a rate far exceeding the
                  general adult population. And this issue hasn’t just been
                  ignored. It’s been romanticized, by things like the 27 Club—a
                  group of musicians whose lives were all lost at just 27
                  years&nbsp;old.
                </p>
                <p>
                  To show the world what’s been lost to this mental health
                  crisis, we’ve used artificial intelligence to create the album
                  the 27 Club never had the chance to. Through this album, we’re
                  encouraging more music industry insiders to get the mental
                  health support they need, so they can continue making the
                  music we all love for years to&nbsp;come.
                </p>
                <p>Because even AI will never replace the real&nbsp;thing.</p>
                {/* </Fade> */}
              </div>
            </div>
          </section>

          <div className="no-gradient-wrapper">
            <section className="blockwrap mental-health" id="the-problem">
              <div className="container">
                {/* mental health in the music industry */}
                <h2>
                  <span className="messinasans">M</span>e
                  <span className="messinasans fix-spacing">n</span>
                  <span className="fix-spacing">t</span>
                  <span className="messinasans">al</span>{" "}
                  <span className="messinasans">h</span>e
                  <span className="messinasans"></span>
                  <span className="messinasans">a</span>
                  <span className="fix-spacing">l</span>t
                  <span className="messinasans">h</span>
                  <br />
                  <span className="messinasans">in th</span>e{" "}
                  <span className="messinasans">m</span>u
                  <span className="messinasans">si</span>c{" "}
                  <span className="messinasans">in</span>du
                  <span className="messinasans"></span>
                  <span className="messinasans">st</span>r
                  <span className="messinasans">y</span>
                </h2>

                <Fade triggerOnce direction="up" delay={100}>
                  <div className="stat-wrapper">
                    <div className="anxiety-stat each-stat">
                      <div className="stat">
                        <h3>
                          <span className="messinasans">7</span>
                          <span className="parabole">1</span>
                          <span className="messinasans">%</span>
                        </h3>
                        <p>
                          of musicians believe they’ve experienced incidences of
                          anxiety and panic attacks.
                        </p>
                      </div>
                    </div>
                    <div className="depression-stat each-stat">
                      <div className="stat">
                        <h3>
                          <span className="messinasans">6</span>
                          <span className="parabole">8</span>
                          <span className="messinasans">%</span>
                        </h3>
                        <p>
                          of musicians have experienced incidences of
                          depression.
                        </p>
                      </div>
                    </div>
                    <div className="suicide-stat each-stat">
                      <div className="stat">
                        <h3>
                          <span className="parabole">2</span>
                          <span className="messinasans">X</span>
                        </h3>
                        <p>
                          Suicide attempts for music industry workers are more
                          than double that of the general population.
                        </p>
                      </div>
                    </div>
                  </div>
                </Fade>
              </div>
            </section>

            <section
              className="blockwrap creating-the-album"
              id="creating-the-album"
            >
              <div className="container">
                {/* Creating the album */}
                <h2>
                  <span className="messinasans">C</span>re
                  <span className="messinasans fix-spacing">a</span>t
                  <span className="messinasans">in</span>g{" "}
                  <span className="messinasans">th</span>e
                  <span className="messinasans">&nbsp;</span>
                  <span className="messinasans">alb</span>u
                  <span className="messinasans">m</span>
                </h2>

                <Fade triggerOnce direction="up" delay={100}>
                  <div className="album-content album-01">
                    <div className="number-output-wrapper">
                      <div className="number">
                        <p>01</p>
                      </div>
                      <div className="output">
                        <p>Music Input</p>
                      </div>
                    </div>

                    <div className="output-description">
                      <p>
                        We had an artificial intelligence (AI) algorithm listen
                        to isolated hooks, rhythms, melodies, and lyrics of 27
                        Club&nbsp;musicians.
                      </p>
                    </div>
                  </div>
                </Fade>

                <Fade triggerOnce direction="up" delay={100}>
                  <div className="album-content album-02">
                    <div className="number-output-wrapper">
                      <div className="number">
                        <p>02</p>
                      </div>
                      <div className="output">
                        <p>AI Input</p>
                      </div>
                    </div>

                    <div className="output-description">
                      <p>
                        Our AI algorithm learned from the music, then generated
                        a string of all-new hooks, rhythms, melodies,
                        and&nbsp;lyrics.
                      </p>
                    </div>
                  </div>
                </Fade>

                <Fade triggerOnce direction="up" delay={100}>
                  <div className="album-content album-03">
                    <div className="number-output-wrapper">
                      <div className="number">
                        <p>03</p>
                      </div>
                      <div className="output">
                        <p>Song Composition</p>
                      </div>
                    </div>

                    <div className="output-description">
                      <p>
                        An audio engineer took these AI-generated musical
                        elements and composed the Lost Tapes of the
                        27&nbsp;Club.
                      </p>
                    </div>
                  </div>
                </Fade>
              </div>
            </section>

            <section className="blockwrap event" id="event">
              <VideoEventBg />
              <div className="container">
                <div className="title-date-wrapper">
                  <h2 className="livestream-title">
                    T<span className="messinasans">H</span>E{" "}
                    <br className="mb-break" />
                    <span className="messinasans">L</span>O
                    <span className="messinasans">S</span>T T
                    <span className="messinasans">A</span>PE
                    <span className="messinasans">S</span>{" "}
                    <span className="messinasans">LIV</span>E
                  </h2>

                  <h2 className="event-date">
                    <span className="messinasans">MA</span>R 2
                    <span className="messinasans">5</span>
                    <br />
                    <span className="messinasans">8</span>P
                    <span className="messinasans">M</span> E
                    <span className="messinasans">S</span>T
                  </h2>
                </div>

                <div className="event-copy">
                  <p>
                    Join Over The Bridge & Lemmon Entertainment on March 25 @ 8
                    PM EST for a virtual broadcast to support mental health in
                    the music&nbsp;industry.
                  </p>

                  <p>
                    Hosted by legendary music journalist Alan Cross, The Lost
                    Tapes Live will feature performances by Jon Harvey (Monster
                    Truck), Tyler Shaw, Francesco Yates, Alexander Saint, and
                    Command Sisters, as well as guest appearances from renowned
                    guitarist Dan&nbsp;Kanter (Justin Bieber), country artist
                    Madeline Merlo and Juno-nominated artist Fefe&nbsp;Dobson.
                  </p>

                  <p>
                    The virtual broadcast will also feature several prominent
                    musicians, music industry professionals, and mental health
                    experts, as we explore the inexorable link between music and
                    mental health. Follow Lemmon Entertainment on{" "}
                    <a
                      onClick={this.gaClick(
                        "Link",
                        "Click",
                        "Lemmon Entertainment Instagram"
                      )}
                      target="_blank"
                      rel="noopener noreferrer"
                      href="https://www.instagram.com/lemmonent/"
                    >
                      Instagram
                    </a>{" "}
                    for more information on how to attend the free&nbsp;event.
                  </p>
                </div>

                <div className="learn-wrapper">
                  <a
                    onClick={this.gaClick(
                      "Link",
                      "Click",
                      "Lost Tapes Live link"
                    )}
                    target="_blank"
                    rel="noopener noreferrer"
                    href="http://losttapeslive.com/"
                  >
                    <img
                      className="lemmon-logo"
                      src={lemmonLogo}
                      alt="Lemmon Entertainment logo"
                    />
                  </a>
                  <a
                    onClick={this.gaClick(
                      "Link",
                      "Click",
                      "Lost Tapes Live link"
                    )}
                    className="learn-btn-link"
                    href="http://losttapeslive.com/"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <LostTapesLiveButton />
                  </a>
                </div>
              </div>
            </section>

            <section className="blockwrap faq" id="faq">
              <div className="container">
                {/* FAQ */}
                <h2>
                  <span className="fix-spacing">F</span>
                  <span className="messinasans">AQ</span>
                </h2>

                <Fade triggerOnce direction="right" delay={500}>
                  <div className="collapsible-wrapper">
                    <Collapsible
                      classParentString="collapsible"
                      contentContainerTagName="div"
                      trigger="What is the 27 Club?"
                    >
                      <p>
                        The 27 Club is a number of musicians, artists, and
                        actors who died at age 27 — most commonly from battling
                        addiction, or where mental health issues had an impact
                        on their cause of death. Unfortunately, the 27 Club is
                        forever ingrained in popular music history. For a full
                        list of 27 Club musicians and artists,{" "}
                        <a
                          href="https://en.wikipedia.org/wiki/27_Club"
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          click&nbsp;here
                        </a>
                        .
                      </p>
                    </Collapsible>
                    <Collapsible
                      classParentString="collapsible"
                      contentContainerTagName="div"
                      trigger="Why is the campaign called the Lost Tapes of the 27 Club? "
                    >
                      <p>
                        ‘Lost Tapes’ is a common naming convention in the music
                        industry for recordings that were never widely released.
                        In our case, these are the songs that were quite
                        literally lost due to 27 Club artists dying&nbsp;young.
                      </p>
                    </Collapsible>
                    <Collapsible
                      classParentString="collapsible"
                      contentContainerTagName="div"
                      trigger="Why did you choose these artists?"
                    >
                      <p>
                        There are, unfortunately, too many amazing 27 Club
                        artists to honour. However, to allow the AI to be as
                        accurate as possible, we chose 27 Club artists with a
                        large catalogue of songs for the algorithm to
                        learn&nbsp;from.
                      </p>
                    </Collapsible>
                    <Collapsible
                      classParentString="collapsible"
                      contentContainerTagName="div"
                      trigger="Are these really new songs by members of the 27&nbsp;Club?"
                    >
                      <p>
                        These artists left us at only 27 years old. These songs
                        were created using artificial intelligence.
                      </p>
                    </Collapsible>
                    <Collapsible
                      classParentString="collapsible"
                      contentContainerTagName="div"
                      trigger="Are you affiliated with the 27 Club artists?"
                    >
                      <p>
                        Lost Tapes of the 27 Club is in no way endorsed by or
                        affiliated with 27 Club artists or their estates and any
                        funds raised through this initiative go directly towards
                        helping musicians and music industry insiders struggling
                        with mental&nbsp;health.
                      </p>
                    </Collapsible>

                    <Collapsible
                      classParentString="collapsible"
                      contentContainerTagName="div"
                      trigger="I need help. What can I do?"
                    >
                      <p>
                        Visit Over The Bridge{" "}
                        <a
                          onClick={this.gaClick("Link", "Click", "Learn More")}
                          href="https://overthebridge.org/"
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          here
                        </a>
                        . Over The Bridge helps the music industry through
                        workshops, peer support, education, and&nbsp;research.
                      </p>
                    </Collapsible>
                  </div>
                </Fade>
              </div>
            </section>

            <section className="blockwrap about" id="about">
              <div className="container">
                <h2>
                  <span className="messinasans">A</span>bou
                  <span className="messinasans">t</span>
                </h2>
                {/* <Fade triggerOnce direction="right" delay={500}> */}
                <div className="about-copy">
                  <p>
                    Lost Tapes of the 27 Club was developed by Over The Bridge,
                    an organization that aims to change the conversation about
                    mental health in the music community while providing a
                    compassionate environment for members to&nbsp;thrive.
                  </p>

                  <p>If you or someone you know needs help, we’re&nbsp;here.</p>
                </div>
                <Fade triggerOnce direction="up" delay={100}>
                  <div className="about-button-wrapper">
                    <div className="learn-wrapper">
                      <a
                        className="bridge-logo-link"
                        onClick={this.gaClick("Link", "Click", "Learn More")}
                        href="https://overthebridge.org"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <OverTheBridgeButton />
                      </a>{" "}
                      &nbsp;&nbsp;&nbsp;
                      <a
                        onClick={this.gaClick("Link", "Click", "Learn More")}
                        className="learn-btn-link"
                        href="https://overthebridge.org/"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <LearnMoreButton />
                      </a>
                    </div>

                    {/* <a className="learn-more" href="#">Le<span className="messinasans">a</span>r<span className="messinasans">n</span> <span className="messinasans">M</span>o<span className="messinasans">re</span></a> <a className="donate" href="#"><span className="messinasans">D</span>o<span className="messinasans">na</span>te</a> */}
                  </div>
                </Fade>
                {/* </Fade> */}
              </div>
            </section>
            <footer>
              <div className="footer-wrapper">
                <div className="site-title">
                  {/* Lost Tapes of the 27 club */}

                  <Scrollchor animate={{ offset: 0, duration: 200 }} to="#top">
                    <span className="messinasans">L</span>o
                    <span className="messinasans">st</span> T
                    <span className="messinasans">a</span>pe
                    <span className="messinasans">s</span>
                    <br />
                    <span className="messinasans">O</span>f t
                    <span className="messinasans">h</span>e{" "}
                    <span className="messinasans">2</span>7{" "}
                    <span className="messinasans">Cl</span>ub
                  </Scrollchor>
                </div>

                <div className="listen-on">
                  {/* Listen on youtube spotify */}
                  <span className="mobile-break">
                    <div className="listen-on-wrapper">
                      <span className="messinasans">Lis</span>te
                      <span className="messinasans">n</span>{" "}
                      <span className="messinasans">on</span> &nbsp;
                    </div>
                    <div className="footer-button-wrapper">
                      <a
                        onClick={this.gaClick("Link", "Click", "Spotify")}
                        href="https://open.spotify.com/artist/0bbvGPgU1zw9zpJB4GEAhI?si=8ZHgPBNYQiWQLKyM17zgmA"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <SpotifyButton />
                      </a>
                      &nbsp;
                      <a
                        onClick={this.gaClick("Link", "Click", "Youtube")}
                        href="https://music.youtube.com/channel/UCY9V---i40sDhehiNNJVQBQ"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <YoutubeButton />
                      </a>
                    </div>
                  </span>
                </div>
              </div>
            </footer>
          </div>
        </Layout>
      </>
    );
  }
}

export default Home;
