import React from 'react';
import MediaQuery from 'react-responsive';
import './style.scss';

import videoEventBg from '../../assets_global/video/bg-animated-event.mp4';
import videoEventBgMobile from '../../assets_global/video/bg-animated-event-mobile.mp4';
import videoEventPoster from '../../assets_global/images/video-bg-event-poster.jpg';
import videoEventPosterMobile from '../../assets_global/images/video-bg-event-poster-mobile.jpg';


class VideoEventBg extends React.Component {

  render() {
    return (
      <>

        <div className="video-event-bg">

          <MediaQuery minWidth={851}>
          <video className="top-video" playsInline loop autoPlay muted poster={videoEventPoster}>
            <source src={videoEventBg} type="video/mp4" />
          </video>
          </MediaQuery>

          <MediaQuery maxWidth={850}>
          <video className="top-video" playsInline loop autoPlay muted poster={videoEventPosterMobile}>
            <source src={videoEventBgMobile} type="video/mp4" />
          </video>
          </MediaQuery>
        </div>

        {/* <div className="video-overlay"></div> */}
       
        {/* <div className="callout-inner">
  
        </div> */}
      </>
    );
  }
}

export default VideoEventBg;