import React from "react";
import './style.scss';

class SpotifyButton extends React.Component {
  render() {
    return (


      <svg version="1.1" className="spotify-button" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px"
	 viewBox="0 0 192 55.7">
        <g>
          <g>
            <path className="st0" d="M46.7,38c-1.5,1.2-3.4,1.8-5.6,1.8c-2.4,0-4.4-0.7-6.2-2c-1.7-1.4-2.6-3.3-2.7-5.9h3c0,1.8,0.6,3.1,1.8,4
			c1.2,0.9,2.6,1.4,4.2,1.4c1.4,0,2.6-0.3,3.5-1c0.9-0.7,1.4-1.6,1.4-2.8c0-0.7-0.2-1.3-0.5-1.8c-0.3-0.5-0.8-0.9-1.5-1.3
			c-0.7-0.3-1.2-0.5-1.6-0.7s-1-0.3-2-0.5c-0.2-0.1-0.4-0.1-0.5-0.1c-0.8-0.2-1.5-0.4-2.1-0.6c-0.6-0.1-1.2-0.4-1.9-0.8
			c-0.7-0.3-1.2-0.7-1.6-1.1c-0.4-0.4-0.8-1-1.1-1.7c-0.3-0.7-0.5-1.4-0.5-2.3c0-2.1,0.7-3.8,2.2-4.9c1.4-1.1,3.2-1.7,5.4-1.7
			s4.1,0.6,5.5,1.7c1.5,1.1,2.2,2.7,2.3,4.8h-2.8c0-1.3-0.5-2.3-1.4-3s-2.1-1-3.5-1s-2.6,0.3-3.5,1c-0.9,0.7-1.4,1.7-1.4,3
			c0,0.6,0.1,1.1,0.3,1.5c0.2,0.4,0.5,0.8,0.7,1c0.3,0.3,0.7,0.5,1.2,0.8c0.5,0.2,1,0.4,1.4,0.5c0.4,0.1,1,0.3,1.7,0.4
			c2.4,0.5,4,1.1,5,1.6c2,1.1,2.9,2.7,2.9,4.9C49,35.2,48.2,36.8,46.7,38z"/>
		        <path className="st0" d="M52.9,16.6h8c2.7,0,4.4,1,5.3,2.6c-1-0.2-2-0.3-2.8-0.3h-7.7v9.6h7.7c0.9,0,2-0.1,3-0.4
			c-0.9,1.8-2.5,2.8-5.2,2.8h-5.4v8.3h-2.8V16.6z M67.2,23.6c0-1.7-0.3-3.2-1-4.4c1.9,0.5,3.7,1.6,3.7,4.4c0,2.7-1.7,4-3.5,4.5
			C66.9,27,67.2,25.4,67.2,23.6z"/>
		      < path className="st0" d="M75.8,36.4c-2-2.3-3.1-5.1-3.1-8.5s1-6.2,3.1-8.5c2-2.2,4.7-3.3,7.9-3.3c3.2,0,5.8,1.1,7.8,3.4
			c2,2.3,3,5,3,8.4s-1,6.2-3,8.4c-2,2.3-4.6,3.4-7.8,3.4C80.5,39.7,77.8,38.6,75.8,36.4z M77.7,21.3c-1.4,1.7-2.2,3.9-2.2,6.6
			c0,2.7,0.8,4.9,2.3,6.6c1.5,1.7,3.5,2.6,5.9,2.6s4.4-0.8,5.8-2.6c1.4-1.7,2.1-3.9,2.1-6.6c0-2.7-0.8-4.9-2.3-6.6
			c-1.5-1.7-3.4-2.6-5.8-2.6C81.1,18.8,79.2,19.6,77.7,21.3z"/>
		        <path className="st0" d="M96.1,16.6h5.1c1.8,0,2.9,0.7,3,2.3h-8.1V16.6z M104.2,18.9h2.7v20.3h-2.7V18.9z M110,16.6h5.1v2.3h-8.1
			C107.1,17.3,108.2,16.6,110,16.6z"/>
		        <path className="st0" d="M119,39.2V16.6h2.8v22.5H119z"/>
		        <path className="st0" d="M127.7,23.5v-6.9h14.6v2.3h-11.8v7.4C128.5,26.2,127.7,25.1,127.7,23.5z M127.7,31.7c0-1.9,0.8-2.8,2.8-3
			v10.5h-2.8V31.7z M130.5,26.3c0.2,0,0.5,0,0.7,0h10.5v2.3h-10.5c-0.2,0-0.5,0-0.7,0V26.3z"/>
		        <path className="st0" d="M152.4,39.2v-8.8l-7.9-13.7h3.3l6.2,11.3l6.2-11.3h3l-7.9,13.7v8.8H152.4z"/>
          </g>
        </g>
        <g>
	        <path className="st1" d="M177.4,1H14.8C7.2,1,1,7.2,1,14.8v26.1c0,7.6,6.2,13.8,13.8,13.8h162.4c7.6,0,13.8-6.2,13.8-13.8V14.5"/>
	        <g>
            <polyline className="st1" points="181.3,1 191,1 191,10.7 "/>
            <line className="st1" x1="191" y1="1" x2="179.3" y2="12.7"/>
	        </g>
        </g>

      </svg>
    );
  }
}

export default SpotifyButton;
