import React from "react";
import {
  AudioPlayerControlSprite,
  AudioPlayer,
  type TrackType,
} from "react-audio-player-pro";
import reactAudioPlayerProStyle from "react-audio-player-pro/dist/style.css";

//import Spinner from 'react-spinkit';
//import { Fade } from 'react-awesome-reveal';

import "./style.scss";

import coverImage from "./i/cover.png";

import SongOne from "../../assets_global/audio/youre-gonna-kill-me.mp3";
import SongTwo from "../../assets_global/audio/the-roads-are-alive.mp3";
import SongThree from "../../assets_global/audio/drowned-in-the-sun.mp3";
import SongFour from "../../assets_global/audio/man-i-know.mp3";

const audioTrackList = [
  {
    // string - path to audio file, required
    src: SongOne,

    // React$Node - custom content instead of title, optional, deafult: <title> or <src>
    // content: <CustomContent/>,

    // MediaMetadata - media meta data, see `mediaMetadata` above
    // https://developer.mozilla.org/en-US/docs/Web/API/MediaMetadata/MediaMetadata
    // optional
    mediaMetadata: {
      title: "You’re Gonna Kill Me",
      artist: "",
      album: "Lost Tapes of the 27 Club",
    },
  },
  {
    src: SongTwo,

    mediaMetadata: {
      title: "The Roads Are Alive",
      artist: "",
      album: "Lost Tapes of the 27 Club",
    },
  },
  {
    src: SongThree,

    mediaMetadata: {
      title: "Drowned in the Sun",
      artist: "",
      album: "Lost Tapes of the 27 Club",
    },
  },
  {
    src: SongFour,

    mediaMetadata: {
      title: "Man, I Know",
      artist: "",
      album: "Lost Tapes of the 27 Club",
    },
  },
  // other tracks here...
];

let TrackNames = Object.values(audioTrackList);
console.log(TrackNames);

// audioTrackList.forEach(function(value,key) {
//   console.log(value,key);
// });

// output song title
//console.log(TrackNames[0].mediaMetadata.title);

//TrackNames.map( ([prop, val]) => console.log(prop, val));

class mediaPlayer extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
    };
  }

  render() {
    return (
      <>
        <img className="cover" src={coverImage} alt="" />

        {/* <div className="media-title"><p>{TrackNames[0].mediaMetadata.title}</p></div> */}
        <AudioPlayerControlSprite />
        <AudioPlayer
          // Array<TrackType> - list of track, see `audioTrackList` above, required
          trackList={audioTrackList}
          // string - wrapper's class name, optional, deafult: ''
          className="media-player"
          // callback function - called on did mount, optional, default: noop
          //onDidMount={console.log}

          // default player state, optional
          defaultState={{
            // boolean - is player muted, optional, default: false
            isMuted: false,

            // number - active song index, optional, default: 0
            activeIndex: 0,

            // boolean - is shuffle on, optional, default: false
            isShuffleOn: false,

            // boolean - is track list open, optional, default: true
            isTrackListOpen: true,

            // string: 'none' | 'all' | 'one' - repeating state, optional, default: 'none'
            repeatingState: "none",
          }}
        />
      </>
    );
  }
}

export default mediaPlayer;
