import React from 'react';
import Scrollchor from 'react-scrollchor';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import _ from 'lodash';

import './style.scss';

class NavBarNoSticky extends React.Component {
  constructor(props){
    super(props)
    this.state = {
      show: false,
      isActive: false
    }
    //this.throttleOnScroll = _.throttle(this.throttleOnScroll.bind(this), 150); // debouncing function to 200ms and binding this


    // fullscreen modal nav
    this.handleShow = () => {
      this.setState({ 
        show: true,
        isActive: 'is-active'
      });
      document.documentElement.style.overflow = 'hidden';
    };

    this.handleHide = () => {
      this.setState({ 
        show: false,
        isActive:  false 
      });
      document.documentElement.style.overflow = '';
    }


    this.handleNavBtn = () => {
      if( this.state.isActive ){
        this.handleHide();

      } else {
        this.handleShow();

      }
    } 

    this.handleScrollClick = (e) => {
      this.setState({ 
        show: false,
        isActive: false
      });
      document.documentElement.style.overflow = '';
    }
}


  render() {
    return (
      <>

      <Modal show={this.state.show} onHide={this.handleHide} className="nav-menu-popup">
          <Modal.Body>
            <div className="nav-menu">
              <ul>
                <li><Scrollchor animate={{ offset: 0, duration: 200 }} beforeAnimate={this.handleScrollClick} to="#the-album">T<span className="messinasans">h</span>e <span className="messinasans">Al</span>bu<span className="messinasans">m</span></Scrollchor></li>
                <li className="nav-the-problem"><Scrollchor animate={{ offset: 0, duration: 200 }} beforeAnimate={this.handleScrollClick} to="#the-problem">T<span className="messinasans">h</span>e <span className="messinasans">Pr</span>ob<span className="messinasans">lem</span></Scrollchor></li>
                <li className="nav-creating-the-album"><Scrollchor animate={{ offset: 0, duration: 200 }} beforeAnimate={this.handleScrollClick} to="#creating-the-album"><span className="messinasans">C</span>re<span className="messinasans">a</span>t<span className="messinasans">in</span>g t<span className="messinasans">h</span>e <span className="messinasans">Al</span>bu<span className="messinasans">m</span></Scrollchor></li>
                <li className="nav-faq"><Scrollchor animate={{ offset: 0, duration: 200 }} beforeAnimate={this.handleScrollClick} to="#faq">F<span className="messinasans">AQ</span></Scrollchor></li> 
                <li className="nav-about"><Scrollchor animate={{ offset: 0, duration: 200 }} beforeAnimate={this.handleScrollClick} to="#about"><span className="messinasans">A</span>bou<span className="messinasans">t</span></Scrollchor></li>             
              </ul>
            </div>
          </Modal.Body>
        </Modal>

        {/* <li className="nav-about"><a onClick={this.handleScrollClick.bind(this)} href="/#about"><span className="messinasans">A</span>bou<span className="messinasans">t</span></a></li>    */}


      { /* https://medium.com/@glweems/react-auto-hide-on-scroll-navbar-617a6749a96 */}

        <div className={`nav-wrapper ${this.state.navClass} ${this.state.lastScrollTop > 138 ? 'minimize' : ''}`}>
          
          <div className="listen-on">
            
              <Button className={this.state.isActive +" hamburger hamburger--collapse js-hamburger"} onClick={this.handleNavBtn} bsPrefix="navbtn"> 
                <div className="hamburger-box">
                  <div className="hamburger-inner"></div>
                </div>
              </Button>
          </div>
        </div>
      </>
    );
  }
}

export default NavBarNoSticky;