import React from "react";
import './style.scss';

class OverTheBridgeButton extends React.Component {
  render() {
    return (
      <svg version="1.1" className="overthebridge-button" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px"
	 viewBox="0 0 1813.1 575.1" >
      
   <g>
      <path className="st0" d="M319.1,244.6c38.5,0,76.3,0,114.9,0c0,1.9,0,3.7,0,5.5c0,57.5,0,115,0,172.5c0,7.1-1.6,8.1-8.4,6.3
        c-19.6-5.1-36.6,0.1-50.7,14.3c-7.1,7.2-11.1,16.1-7.5,26.4c3.6,10.3,12.4,14.4,22.4,16c19.5,3.2,42.1-7.4,51.9-24.8
        c2.6-4.6,4.2-10.3,4.3-15.6c0.4-23.3,0.2-46.7,0.2-70c0-2.1,0-4.3,0-6.8c39.8,0,79.2,0,118.6,0c-30.3,109.2-140.1,216.7-296,205.9
        C121.3,564.1,26.1,448.4,8.4,345.8c54,0,107.9,0,162.4,0c0.1,1.3,0.3,2.8,0.3,4.2c0,16.3,0,32.7,0,49c0,6.4-1.9,7.7-7.9,5.9
        c-19.2-5.9-43.1,2.1-54.3,18.1c-10.7,15.3-5.2,31.4,12.6,37c22.1,6.9,50.8-6.3,59.6-27.7c1.4-3.4,2.1-7.4,2.1-11.1
        c0.1-50,0.1-100,0.1-150c0-29.3-0.2-58.7-0.3-88c0-1.8,0-3.6,0-5.7c41.4,0,82.3,0,124,0c0,1.9,0,3.6,0,5.4c0,51.8,0,103.7,0,155.5
        c0,7.5-1.5,8.6-8.6,6.5c-21-6.2-46.3,3.9-56.4,22.6c-6.1,11.3-2.8,23.2,8.2,29.7c8.4,5,17.7,5.8,27.1,4.3
        c13.8-2.3,25.4-8.8,34.3-19.8c5.1-6.3,7.7-13.3,7.7-21.6c-0.2-36.5-0.1-73-0.1-109.5C319.1,248.8,319.1,247,319.1,244.6z"/>
      <path className="st0" d="M41.8,277.5c-12.1,0-23.9,0-35.7,0c-1.1,0-2.3-0.2-4.4-0.4c7.8-103.7,54.4-184,143.9-237
        c98.1-58.1,219-52.9,310.9,14.6c81.8,60.1,122.1,142.4,120.4,245.2c-12.7,0-24.5,0-36.3,0c-1.3,0-2.6-0.2-3.9-0.3
        C542.6,163.5,439.3,54.3,314,41.6C173,27.4,45.5,139.4,41.8,277.5z"/>
      <path className="st0" d="M800.9,389.9c7.5,4.5,15.4,8.7,22.5,13.8c12,8.7,17.8,21.4,19.4,35.7c2,17-1.7,33-11.4,47.4
        c-9.4,13.8-22.9,21.6-38.7,24.8c-11.8,2.4-23.9,3.8-35.9,4c-29.6,0.6-59.3,0.2-89.4,0.2c0-78.2,0-156.2,0-235c1.4,0,3,0,4.6,0
        c29.5,0,59-0.4,88.5,0.2c16.2,0.3,32.2,3,46.2,12.5c19.8,13.6,32.1,42.1,21.5,67.1c-4.7,11-12.1,19.7-22.2,26.1
        C804.1,388,802.2,389.2,800.9,389.9z M698.2,486.8c24.4,0,48.4,0.3,72.3-0.1c20.5-0.3,38.2-11.9,39.8-35.6
        c0.8-11.4-1.7-21.6-9.6-30.2c-9.6-10.3-22.4-12.6-35.3-13c-21-0.6-42-0.2-63-0.2c-1.4,0-2.8,0.2-4.2,0.3
        C698.2,434.8,698.2,460.9,698.2,486.8z M698.3,380.2c25.3-0.8,50.4-0.2,75-3c19-2.2,27.4-14.2,27.6-31.4
        c0.2-23.1-12.5-34.9-34.7-35.9c-19.3-0.9-38.6-0.8-57.9-1.1c-3.3-0.1-6.6,0-10,0C698.3,332.5,698.3,355.6,698.3,380.2z"/>
      <path className="st0" d="M873.4,280.8c2,0,3.6,0,5.3,0c35.3,0,70.7-0.3,106,0.2c11.5,0.2,23.2,1.6,34.3,4.3
        c23.2,5.6,35.9,22.3,40.8,44.5c4.2,18.9,1.6,37.3-10.8,53.2c-9.6,12.3-22.8,18.8-37.5,22.7c-4.8,1.3-9.6,2.2-15.4,3.4
        c13.2,6.1,23.4,14.9,31.7,25.9c6.4,8.5,12.4,17.2,18.2,26.1c10.8,16.5,21.2,33.1,31.8,49.7c0.9,1.3,1.6,2.8,2.8,4.9
        c-13,0-25.2,0.1-37.5-0.2c-1.4,0-2.9-2.3-4-3.8c-14.5-22-28.6-44.3-43.3-66.2c-6.8-10.2-13.9-20.4-24.6-27.2
        c-6.9-4.4-14.2-6.8-22.5-6.7c-13,0.2-26,0-39,0c-1.6,0-3.2,0-5.3,0c0,34.7,0,69,0,103.6c-10.5,0-20.6,0-31,0
        C873.4,437.5,873.4,359.5,873.4,280.8z M904.9,306.9c-0.3,1.1-0.5,1.6-0.5,2.1c0,24,0,47.9-0.1,71.9c0,3,1.3,3.7,3.9,3.6
        c4.2-0.1,8.3,0,12.5,0c24.2-0.5,48.5-0.6,72.7-1.8c12.5-0.7,23.4-6.6,30.1-17.7c12.4-20.3,4.8-47.7-22-55.1
        c-13.1-3.6-26.6-2.9-40-2.9C942.7,306.9,923.9,306.9,904.9,306.9z"/>
      <path className="st0" d="M1575.7,354.4c-1.5-4.2-2.8-7.8-4.1-11.3c-7.2-19.6-21.3-31.6-41.4-36.3c-26.8-6.3-52.5-3.9-75.3,12.6
        c-15,10.8-23.4,26.4-28.1,44c-6.9,25.9-6.6,51.5,2.1,76.9c9.8,28.8,31.4,43.9,60.1,49.6c29.8,5.9,56.8-2,82-18
        c4.4-2.8,6.2-6,6-11.3c-0.4-12-0.1-23.9-0.1-36.4c-23.2,0-46.1,0-69.4,0c0-9.5,0-18.6,0-27.9c33.2,0,66.1,0,99.3,0
        c0.1,1.5,0.2,3,0.2,4.4c0,26.2,0,52.3,0.1,78.5c0,2.9-0.7,4.7-3.2,6.6c-20.7,15.9-43.6,27.6-69.4,31.9c-35.9,6-69.9,0.6-100.2-20.6
        c-22.7-15.9-35.3-38.7-40.9-65c-7.8-36.6-3.5-72,15.4-104.8c14.4-25.1,36.7-39.4,64.4-46.2c13.4-3.3,27.1-4.8,40.9-3.9
        c23.7,1.4,46.1,7.1,64.4,23.3c13.2,11.7,20,27.3,24.5,43.9c0.2,0.9-0.6,2.8-1.3,3.1C1593.1,349.7,1584.4,352,1575.7,354.4z"/>
      <path className="st0" d="M1175.2,515.8c0-78.6,0-156.4,0-235c1.6,0,3.2,0,4.7,0c28.3,0,56.7-0.4,85,0.1c20,0.4,40,2.4,57.5,13.8
        c21.7,14.2,35,34.6,40.8,59.6c8.7,37.2,8,73.9-8.4,109c-12.9,27.6-34.4,45-65.1,49.3c-12,1.7-24.1,2.7-36.1,3
        c-24.2,0.4-48.3,0.1-72.5,0.1C1179.3,515.8,1177.4,515.8,1175.2,515.8z M1206.3,487.3c0.7,0.2,1.2,0.4,1.6,0.4
        c23.7-0.6,47.5-0.6,71.2-2.1c19.4-1.2,34.3-11,43.8-28.3c7.7-14.1,10.9-29.4,12.3-45.4c1.6-17.5,0.8-34.8-3.3-51.8
        c-4.6-18.8-14.7-33.9-31.8-43.7c-9-5.1-18.9-6.5-29.1-6.7c-21-0.3-41.9-0.6-62.9-0.9c-0.6,0-1.2,0.3-1.8,0.4
        C1206.3,368.8,1206.3,428,1206.3,487.3z"/>
      <path className="st0" d="M1808.9,281.2c0,9.2,0,18,0,27.5c-46.1,0-92.1,0-138.4,0c0,23.9,0,47.1,0,71c43,0,86,0,129.3,0
        c0,9.6,0,18.5,0,28.1c-43,0-86,0-129.3,0c0,26.9,0,53.1,0,79.8c47.7,0,95.3,0,143.3,0c0,9.5,0,18.5,0,27.9c-58,0-116,0-174.4,0
        c0-78.1,0-156,0-234.3C1695.7,281.2,1752.1,281.2,1808.9,281.2z"/>
      <path className="st0" d="M1209.1,165.6c0,26.2,0,51.5,0,77c-12.4,0-24.4,0-36.7,0c0-60.4,0-120.8,0-181.8c1.6-0.1,3.3-0.3,5.1-0.3
        c29.7,0.1,59.3-0.1,89,0.5c12.3,0.3,24.8,1.9,35.3,9.7c12.1,9.1,18.3,21.6,19.3,36.5c0.9,13.5-2.1,26-11.1,36.6
        c-8.4,9.8-19.4,14.5-31.7,17c-1.3,0.3-2.5,0.6-4.7,1.2c24.8,13.9,35.4,38.4,49.6,60c4.3,6.6,8.4,13.4,13,20.8
        c-14.9,0-28.8,0.1-42.7-0.2c-1.4,0-3.1-2.1-4.2-3.6c-12.8-18.7-25.4-37.5-38.1-56.3c-5.1-7.6-11.3-14.2-20.8-15.7
        C1223.6,166.1,1216.8,166.1,1209.1,165.6z M1209.6,136.8c1.9,0,3.5,0,5.1,0c15.3-0.3,30.6-0.6,45.9-1c8-0.2,15.2-2.7,19.1-10.3
        c4.2-8.2,4.4-16.7-0.4-24.6c-4.6-7.6-12.7-8.6-20.4-8.8c-15.3-0.5-30.7-0.1-46-0.1c-1.1,0-2.2,0.2-3.4,0.3
        C1209.6,107.1,1209.6,121.7,1209.6,136.8z"/>
      <path className="st0" d="M654.4,151.9c0.2-16.1,1.9-32,9-46.5C676,79.5,696,62.7,725.5,58.7c23.6-3.2,46.2-1.4,66.8,11.7
        c21.7,13.8,33.9,33.8,37.9,59.1c3,18.8,2.8,37.5-2.7,55.8c-9.5,32.1-30.8,53-63.8,59c-27.9,5.1-55.3,1.8-78.5-17.3
        c-15.7-12.9-24.4-29.9-28.1-49.5C655.6,169.1,655.3,160.5,654.4,151.9z M793.9,150.3c-1.3-8.6-2.1-17.4-4-25.8
        c-2.6-11.4-8.3-21.4-18.5-27.7c-11-6.7-22.9-9.2-35.9-7c-17,2.9-30,10.7-36.5,27.3c-7.3,18.8-7.9,38.2-3.2,57.5
        c5.5,22.8,19.3,37.1,43.7,39.6c17.8,1.8,31.8-4.9,42.2-19C791.3,181.9,793.1,166.3,793.9,150.3z"/>
      <path className="st0" d="M1492.4,60.7c12.3,0,24.4,0,37.1,0c0,23.6,0,47,0,70.7c24.3,0,48.1,0,72.5,0c0-23.5,0-47,0-70.8
        c12.6,0,24.5,0,36.8,0c0,60.6,0,121,0,181.8c-12,0-24.1,0-36.5,0c0-26.4,0-52.6,0-79.3c-24.3,0-48.2,0-72.6,0c0,26.3,0,52.7,0,79.3
        c-12.6,0-24.7,0-37.2,0C1492.4,182.2,1492.4,121.8,1492.4,60.7z"/>
      <path className="st0" d="M1147.2,60.9c0,10.4,0,20.3,0,30.8c-33,0-65.7,0-98.7,0c0,13.2,0,25.7,0,38.8c30.5,0,60.9,0,91.8,0
        c0,10.5,0,20.5,0,31.2c-30.4,0-61,0-91.8,0c0,16.9,0,33.1,0,49.9c34.1,0,68,0,102.2,0c0,10.5,0,20.6,0,30.9c-46.5,0-92.7,0-139.4,0
        c0-60.4,0-120.8,0-181.7C1056.5,60.9,1101.7,60.9,1147.2,60.9z"/>
      <path className="st0" d="M1667.3,242.5c0-61,0-121.8,0-182.7c45,0,89.7,0,134.8,0c0,10.3,0,20.4,0,31c-32.6,0-65,0-97.7,0
        c0,13.5,0,26.6,0,40.2c30.4,0,60.7,0,91.2,0c0,10.4,0,20.3,0,30.8c-30.2,0-60.4,0-91.1,0c0,16.8,0,33.2,0,50.1
        c33.7,0,67.4,0,101.4,0c0,10.5,0,20.4,0,30.7C1759.8,242.5,1713.7,242.5,1667.3,242.5z"/>
      <path className="st0" d="M831.9,60.5c12.9,0,25.2-0.1,37.5,0.2c1.3,0,3.2,2.4,3.8,4.1c14.7,42.2,29.3,84.4,43.9,126.6
        c0.3,0.7,0.7,1.4,1.4,2.7c7.7-23.1,15.1-45.6,22.6-68.1c6.9-20.6,13.9-41.2,21-61.8c0.5-1.4,2.1-3.5,3.3-3.5
        c12.1-0.3,24.3-0.2,37.2-0.2c-3,8.7-5.8,16.8-8.6,24.8c-11.9,32.8-23.8,65.6-35.6,98.4c-6.6,18.3-13.3,36.5-19.8,54.8
        c-1.1,3.1-2.3,4.5-5.8,4.4c-10.5-0.3-21-0.2-31.5,0c-3,0-4.2-0.9-5.2-3.7c-16.3-45.8-32.8-91.5-49.3-137.3
        c-4.7-12.9-9.5-25.8-14.2-38.7C832.2,62.6,832.2,62,831.9,60.5z"/>
      <path className="st0" d="M1424.6,242.6c-12.9,0-24.9,0-37.4,0c0-50.2,0-100.2,0-150.6c-18.2,0-35.8,0-53.8,0c0-10.5,0-20.7,0-31.2
        c48.2,0,96.5,0,145.1,0c0,10.2,0,20.2,0,30.8c-17.9,0-35.6,0-53.9,0C1424.6,142.1,1424.6,192.1,1424.6,242.6z"/>
      <path className="st0" d="M1102.5,281.1c10.5,0,20.6,0,31.2,0c0.1,2,0.2,3.8,0.2,5.6c0,74.4,0,148.9,0.1,223.3c0,4.4-0.8,6.2-5.7,6
        c-8.4-0.4-16.9-0.1-25.8-0.1C1102.5,437.4,1102.5,359.5,1102.5,281.1z"/>
      <path className="st0" d="M170.9,300.5c0,7.4,0,14.5,0,21.9c-55.6,0-111,0-167,0c-0.6-7.2-1.3-14.4-1.9-21.9
        C58.5,300.5,114.6,300.5,170.9,300.5z"/>
      <path className="st0" d="M574.7,323.1c-1,6.2-2,12.1-2.8,18c-0.4,3.5-2,4.5-5.5,4.5c-19.3-0.2-38.6-0.1-57.9-0.1c-18.8,0-37.6,0-56.4,0
        c-1.8,0-3.6,0-5.6,0c0-7.7,0-14.8,0-22.4C488.9,323.1,531.4,323.1,574.7,323.1z"/>
      <path className="st0" d="M183.2,155.1c0-7.5,0-14.6,0-21.9c41.3,0,82.3,0,123.6,0c0,7.4,0,14.4,0,21.9
        C265.7,155.1,224.7,155.1,183.2,155.1z"/>
      <path className="st0" d="M319.3,222c0-7.4,0-14.4,0-21.8c38.1,0,76.1,0,114.4,0c0,7.2,0,14.3,0,21.8C395.7,222,357.7,222,319.3,222z"/>
      <path className="st0" d="M455.6,129.1c4.5,2.7,5.6,6.1,5.6,10.8c-0.2,51.1-0.1,102.3-0.1,153.4c0,2,0,3.9,0,6.3
        c-1.9,0.1-3.5,0.2-5.5,0.3C455.6,242.9,455.6,186.2,455.6,129.1z"/>
      <path className="st0" d="M141.8,276.8c-1.8,0-3.5,0-5.7,0c0-2,0-3.8,0-5.5c0-50,0.1-99.9-0.1-149.9c0-4.5,1.7-7,5.8-9.1
        C141.8,167.3,141.8,221.9,141.8,276.8z"/>
      <path className="st0" d="M354.3,176.4c-1.8,0-3.4,0-5.2,0c0-36.9,0-73.7,0-110.5c3.7-0.1,5.7,0.7,5.6,5c-0.1,34-0.1,68-0.1,102
        C354.7,173.9,354.5,175.1,354.3,176.4z"/>
      <path className="st0" d="M88.5,175.4c0,34.7,0,67.9,0,101.3c-1.9,0-3.5,0-5.7,0c0-2.1,0-4,0-6c0-26.1,0.2-52.3-0.1-78.4
        C82.5,186.3,84.7,181.5,88.5,175.4z"/>
      <path className="st0" d="M407.8,176.4c-1.9,0-3.4,0-5.4,0c0-29.5,0-58.9,0-88.5c3.3,0.8,5.7,1.7,5.7,6c-0.1,26.5-0.1,52.9-0.1,79.4
        C408,174.3,407.9,175.2,407.8,176.4z"/>
      <path className="st0" d="M513.8,299.6c-2.2,0-3.8,0-5.6,0c0-27.6,0-54.9,0-82.3c0.3-0.1,0.6-0.2,0.8-0.3c1.6,5.7,4.4,11.5,4.6,17.2
        C514.1,255.8,513.8,277.4,513.8,299.6z"/>
      <path className="st0" d="M295.7,57.9c1.9,0,3.3,0,5.2,0c0,17.1,0,34.1,0,51.5c-1.7,0.1-3.2,0.2-5.2,0.3
        C295.7,92.4,295.7,75.4,295.7,57.9z"/>
      <path className="st0" d="M247.8,109.5c-2.1,0-3.5,0-5.7,0c0-3.7,0-7.3,0-10.9c0-10.6,0.2-21.3-0.1-31.9c-0.1-4.5,1.8-5.4,5.8-4.8
        C247.8,77.7,247.8,93.4,247.8,109.5z"/>
      <path className="st0" d="M194.3,109.4c-1.6,0-3,0-4.9,0c0-9.1-0.2-18.1,0.2-26.9c0.1-1.5,2.4-2.8,3.6-4.2c0.4,0.2,0.7,0.4,1.1,0.7
        C194.3,89,194.3,99.1,194.3,109.4z"/>
    </g>

      </svg>

    );
  }
}

export default OverTheBridgeButton;