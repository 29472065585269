import React from 'react';
import { BrowserRouter as Router, Route, Link, Switch, Redirect } from "react-router-dom";
import HomePg from './pages/home/home';

import './assets_global/scss/normalize.scss';
import './assets_global/scss/styles.scss';

function App() {
  return (
    <Router>
      <Switch>
        <Route exact path="/" render={(props) => <HomePg {...props} />}
        />

      </Switch>
    </Router>
  );
}

export default App;
