import React from "react";
import './style.scss';

class LemmonentButton extends React.Component {
  render() {
    return (
      <svg className="lemmonent-button" data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 366.32 79.03">
      <defs>
        
        </defs>
        <path className="cls-1" d="M40.78,28.74a18.26,18.26,0,0,1,5,2.45,11.67,11.67,0,0,1,3.69,4.53,15.76,15.76,0,0,1,1.36,6.76,16.63,16.63,0,0,1-2.2,8.7q-2.18,3.68-5.8,3.68a5.46,5.46,0,0,1-3.25-1,4.62,4.62,0,0,1-1.93-3A7.86,7.86,0,0,1,30.51,55a6.54,6.54,0,0,1-5.45-2.42,9.42,9.42,0,0,1-1.93-6,11.85,11.85,0,0,1,2.68-7.64A8.65,8.65,0,0,1,33,35.59c2.68,0,4.52,1,5.53,3L39.07,36H42.8L40.48,46.48a9.55,9.55,0,0,0-.31,2.41A4,4,0,0,0,41,51.7a2.55,2.55,0,0,0,2.11,1,3.68,3.68,0,0,0,3-1.63,10.37,10.37,0,0,0,1.84-3.77,17.19,17.19,0,0,0,.57-4.57c0-4-1.31-7.16-3.9-9.4A14.19,14.19,0,0,0,35,29.92a15.78,15.78,0,0,0-8.69,2.5,17,17,0,0,0-6,6.37A17.53,17.53,0,0,0,18.3,47a12.37,12.37,0,0,0,4.13,9.8,14.23,14.23,0,0,0,9.75,3.64,13.62,13.62,0,0,0,5.53-1.1l.57,1.89a15.73,15.73,0,0,1-6.63,1.36,17.1,17.1,0,0,1-5.8-1,16,16,0,0,1-5-2.86,13.25,13.25,0,0,1-3.56-4.92A16,16,0,0,1,16,47.09a18.47,18.47,0,0,1,2.68-9.83,18.72,18.72,0,0,1,7.07-6.9A19.44,19.44,0,0,1,35.29,28,18.93,18.93,0,0,1,40.78,28.74Zm-5.13,20.5a10.22,10.22,0,0,0,1.75-5.75,5.77,5.77,0,0,0-1-3.6,3.92,3.92,0,0,0-3.16-1.32,5.38,5.38,0,0,0-4.57,2.37,9.06,9.06,0,0,0-1.71,5.45,6.69,6.69,0,0,0,1.09,4,3.65,3.65,0,0,0,3.17,1.58A5.22,5.22,0,0,0,35.65,49.24Z"/>
        <path className="cls-1" d="M56.29,56.93V24h4V53.28H77v3.65Z"/>
        <path className="cls-1" d="M82.08,33.18V23.74h21.57v3.42H86.07v10.9C83.08,37.73,82.08,36.26,82.08,33.18Zm0,13.27c0-2.56,1-4.45,4-4.93v12h18.06v3.41h-22Zm4-8.39a13.4,13.4,0,0,0,1.47,0h15.21v3.32H87.54a12.74,12.74,0,0,0-1.47.09Z"/>
        <path className="cls-1" d="M137.85,56.93V30.05L127.49,56.93h-3.07L114.1,30.05V56.93h-3.87V24H116l10,26.18L136.05,24h5.76V56.93Z"/>
        <path className="cls-1" d="M176.9,56.93V30.05L166.53,56.93h-3.07L153.14,30.05V56.93h-3.87V24h5.8l10,26.18L175.1,24h5.75V56.93Z"/>
        <path className="cls-1" d="M186.21,40.45a11.81,11.81,0,0,1,7-11.32,24.58,24.58,0,0,0-2.32,11.32,23.84,23.84,0,0,0,2.7,11.88C189,50.29,186.21,46.35,186.21,40.45Zm15.87-16.57A9.19,9.19,0,0,1,210.53,29a20.3,20.3,0,0,0-8.45-1.58,21.41,21.41,0,0,0-8.82,1.72A9.52,9.52,0,0,1,202.08,23.88Zm-8.44,28.45a20.54,20.54,0,0,0,8.44,1.58,20.3,20.3,0,0,0,8.45-1.58,9.1,9.1,0,0,1-8.45,5.16A9.43,9.43,0,0,1,193.64,52.33ZM212.9,40.45c0-4.46-.74-8.49-2.37-11.46,4.5,2,7.1,5.84,7.1,11.46,0,5.9-2.6,9.89-7.1,11.88C212.16,49.32,212.9,45.14,212.9,40.45Z"/>
        <path className="cls-1" d="M243.9,56.93l-17-26.75V56.93H223V24h4.83l16.34,26V24h3.91V56.93Z"/>
        <path className="cls-1" d="M255.6,33.18V23.74h21.57v3.42H259.58v10.9C256.59,37.73,255.6,36.26,255.6,33.18Zm0,13.27c0-2.56,1-4.45,4-4.93v12h18.06v3.41h-22Zm4-8.39a13.58,13.58,0,0,0,1.47,0h15.22v3.32H261.05a13,13,0,0,0-1.47.09Z"/>
        <path className="cls-1" d="M304.65,56.93l-17-26.75V56.93h-3.91V24h4.83l16.34,26V24h3.91V56.93Z"/>
        <path className="cls-1" d="M313.5,23.74H321c2.61,0,4.27,1.05,4.46,3.32H313.5Zm11.95,3.32h4V56.93h-4Zm8.49-3.32h7.49v3.32H329.48C329.67,24.79,331.33,23.74,333.94,23.74Z"/>
        <path className="cls-2" d="M345.86,1h-325A19.81,19.81,0,0,0,1,20.81V58.22A19.81,19.81,0,0,0,20.81,78H345.5a19.82,19.82,0,0,0,19.82-19.81V20.43"/><polyline class="cls-2" points="351.35 1 365.32 1 365.32 14.96"/><line className="cls-2" x1="365.32" y1="1" x2="348.52" y2="17.8"/></svg>

    );
  }
}

export default LemmonentButton;